<template>
  <transition name="slide">
    <div class="msg">
      <h :isback="true">
        <span class="name">消息</span>
      </h>
      <cube-scroll
        ref="scroll"
        :data="list"
        :options="options"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
      >
        <ul class="list">
          <li
            v-for="item in list"
            :key="item.id + item.createTime"
            class="border-bottom-1px"
            @click="choose(item)"
          >
            <div class="title" v-text="item.title" />
            <div class="date" v-text="item.createTime" />
          </li>
        </ul>
      </cube-scroll>
      <msg-content ref="content" :article="content" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import MsgContent from "@/components/user/msg-content";
import { mapState } from "vuex";
import { msg } from "@/api/user";
export default {
  components: {
    h,
    MsgContent
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      options: {
        click: true,
        pullDownRefresh: {
          threshold: 60,
          // stop: 44,
          stopTime: 1000,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      },
      content: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  created() {
    this.getMsgList();
  },
  methods: {
    getMsgList() {
      const params = {
        userId: this.user.id,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      msg(params).then(res => {
        if (!res) {
          return;
        }
        if (this.pageNumber == 1) {
          this.list = res.object;
        } else {
          this.list = this.list.concat(res.object);
        }
        if (this.list.length >= this.pageSize) {
          this.hasNext = true;
        } else {
          this.hasNext = false;
        }
      });
    },
    onPullingDown() {
      this.pageNumber = 1;
      this.getMsgList();
    },
    onPullingUp() {
      if (!this.hasNext) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this.getMsgList();
    },
    choose(item) {
      this.content = item;
      this.$refs.content.show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.msg
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .list
    li
      padding 10px
      .title
        font-size 14px
      .date
        font-size 12px
        color #999
</style>
