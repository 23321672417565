<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">{{ article.title }}</span>
      </h>
      <div class="article" v-text="article.content" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
export default {
  props: {
    article: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    h
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .article
    padding 15px
    font-size 14px
</style>
